<template>
	<div class="animated fadeIn">
		<b-row>
			<b-col
				v-if="showReports"
				class="col-12">
				<b-tabs v-model="tabIndex">
					<b-tab
						v-for="(tabInfo, index) in tabs"
						:key="index">
						<template slot="title">
							<router-link
								:class="$route.name === tabInfo.name ? 'nav-link ' + $router.options.linkActiveClass : 'text-gray-dark'"
								:to="{ name: tabInfo.name }"
								class="list-group-item btn text-left">
								{{ translate(tabInfo.translate_key) }}
							</router-link>
						</template>
						<template
							v-if="tabIndex === index">
							<router-view class="p-2" />
						</template>
					</b-tab>
				</b-tabs>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import {
	Network,
} from '@/translations';
import { admin } from '@/settings/Roles';
import UnilevelSummary from '@/util/UnilevelSummary';
import Alert from '@/util/Alert';

export default {
	name: 'UnilevelSummary',
	messages: [Network],
	data() {
		return {
			tabIndex: 0,
			showReports: false,
			unilevelSummary: new UnilevelSummary(),
			admin,
			alert: new Alert(),
			tabs: [
				{
					name: 'UnilevelSummaryData',
					translate_key: 'summary',
				},
				{
					name: 'UnilevelSummaryDetails',
					translate_key: 'detail_view',
				},
			],
		};
	},
	computed: {
		isAdminOrCorporate() {
			return this.admin.includes(this.$user.details().type);
		},
		termsAccepted() {
			try {
				return this.unilevelSummary.data.response.data.response.show;
			} catch (e) {
				return false;
			}
		},
	},
	mounted() {
		if (this.isAdminOrCorporate) {
			this.showReports = true;
		} else {
			this.unilevelSummary.getUnilevelSummaryTerms().then(() => {
				const style = getComputedStyle(document.body);
				this.showReports = this.termsAccepted;

				if (!this.showReports) {
					const config = {
						config: {
							allowEscapeKey: false,
							allowOutsideClick: false,
							showLoaderOnConfirm: true,
							confirmButtonColor: style.getPropertyValue('--primary'),
							confirmButtonText: this.translate('acknowledge_and_agree'),
							cancelButtonColor: style.getPropertyValue('--muted'),
							cancelButtonText: this.translate('cancel'),
							showCancelButton: true,
							isHtml: true,
							reverseButtons: true,
						},
					};

					this.alert.confirmation(
						this.translate('unilevel_summary_terms_title'),
						this.translate('unilevel_summary_terms_body', { mba: '#/member-business-agreements' }),
						config,
						true,
					).then((result) => {
						if (result.value) {
							this.unilevelSummary.updateUnilevelSummaryTerms();
							this.showReports = true;
						} else {
							this.$router.push({ name: 'Home' });
						}
					}).catch(() => {
						this.$router.push({ name: 'Home' });
					});
				}
			});
		}
	},
};
</script>
